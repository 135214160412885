* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #fafafa !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media screen and (max-width: 720px) {
  html {
    font-size: 87.5%;
  }

  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}
/* @media screen and (max-width: 340px) { */
@media screen and (max-width: 375px) {
  html {
    font-size: 75%;
  }

  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 340px) {
  html {
    font-size: 68.75%;
  }

  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}

body,
input,
textarea,
button {
  font: 400 1rem 'Inter', sans-serif;
}

button {
  cursor: pointer;
  outline: none;
}

a {
  /* text-decoration: none; */
  color: inherit;
}
